import Button from "@/components/Common/Buttons/Button/Button";
import BlogPostItem from "@/components/Components/BlogPostItem/BlogPostItem";

export default {
    name: 'IndexBlogPost',
    props: {
        title: {
            type: String,
            default: 'no'
        },
        category: {
            type: Number,
            default: null
        }
    },
    components: {
        Button,
        BlogPostItem
    },
    data() {
        return {
            posts: [],
        }
    },
    async mounted() {
        const payload = {};
        if (this.$props.category !== null) {
            payload.categoy = this.$props.category;
        }
        this.posts = await this.$store.dispatch('blog/getFeaturedPosts', payload);
    }
}