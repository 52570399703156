<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{
    'single-property-marketplace-carousel': true,
  }">
    <div class="container blog-container">
      <div class="blog-container-title"> {{ title }}</div>
      <div class="blog-post-content row">
        <div v-for="(item, index) in posts" :key="index" class="blog-post-item col-12 col-md-6" >
          <BlogPostItem :id="item.id"  :size="'regular'" :title="item.title"
          :category="item.category" :date="item.date" :image="item.picture"
          :description="item.short_description"/>
        </div>
      </div>
      <div class="read-more-button d-flex justify-content-center">
        <div class="col-md-2">
          <Button @click="$router.push({name: 'BlogCategory'})" :size="'medium'" :text="$t('read-more')" :theme="'solid-white'"/>
        </div>
      </div>
    </div>
  </div>

</template>

